import { PureComponent } from 'react'

import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import debounce from 'debounce'
import { withRouter } from 'next/router'
import Autosuggest from 'react-autosuggest'

import { Button } from '@/stories/buttons/button'
import { breakpoint, Colors, lightTheme } from '@/theme'
import { A11yHiddenLabel } from '@components/form-inputs/label'
import { track } from '@helpers/analytics'
import { addressErrorMessages, isMobile } from '@helpers/constants'
import errorHandler from '@helpers/error-handler'

import defaultTheme from './default-theme.module.scss'
import { getSuggestionValue, renderSuggestion } from './Suggestion'
import withMaps from './with-maps'

import { bool, func, object, string } from 'prop-types'

const EMPTY_OBJECT = {}
class AutoComplete extends PureComponent {
  static propTypes = {
    theme: object,
    addressLoading: bool,
    maps: object,
    onSubmitSuggestion: func,
    residentialOnlyError: bool,
    formLocation: string
  }

  static defaultProps = {
    theme: null,
    addressLoading: false,
    maps: EMPTY_OBJECT,
    onSubmitSuggestion: function () {
      console.warn('AutoComplete onSubmitSuggestion() not implemented')
    },
    residentialOnlyError: false,
    value: '',
    formLocation: ''
  }

  state = {
    highlightedSuggestion: null,
    suggestions: [],
    value: '',
    noAddressError: false
  }

  componentDidMount() {
    debounce(this.retrieveSuggestions(this.state.value), 200)
  }

  onChange = (event, { newValue, method }) => {
    const newStateValues = { value: newValue }
    const { suggestions, noAddressError } = this.state
    newStateValues.highlightedSuggestion = suggestions.length
      ? suggestions.find((suggestion) => getSuggestionValue(suggestion) === newValue)
      : null
    if (noAddressError) newStateValues.noAddressError = false
    this.setState(newStateValues)
  }

  handleSelect = (event, { suggestion, suggestionValue, method }) => {
    this.setState({
      value: suggestionValue
    })

    this.submitSuggestion(suggestion, suggestionValue)
  }

  handleClick = () => {
    if (this.state.highlightedSuggestion) {
      const suggestion = this.state.highlightedSuggestion
      this.submitSuggestion(suggestion, getSuggestionValue(suggestion))
    } else {
      this.setState({ noAddressError: true })
      this.input.focus()
    }
  }

  // as suggested by https://github.com/moroshko/react-autosuggest/blob/master/FAQ.md#how-do-i-get-the-input-field
  saveInput = (autosuggest) => {
    // For some reasons saveInput is called after unmount and autosuggest doesn't exist at that point
    this.input = autosuggest ? autosuggest.input : null
  }

  submitSuggestion(suggestion, suggestionValue) {
    const { onSubmitSuggestion } = this.props
    // Since all of our data is coming from the autocomplete, we need to hardcode group to suggestions
    track('Places.Select', {
      value: suggestionValue,
      id: suggestion.id,
      group: 'suggestions',
      entryComponent: 'page',
      destination: '/menu'
    })

    onSubmitSuggestion(suggestion)
  }

  retrieveSuggestions = (location) => {
    if (!location || !location.value) return

    track('Places.Input', { value: location.value, entryComponent: 'page', destination: '/menu' })
    const { maps } = this.props

    maps.fetch &&
      maps
        .fetch(location.value)
        .then((suggestions = []) => {
          this.setState({ suggestions })
        })
        .catch((err) => {
          errorHandler(new Error(err.message))
        })
  }

  render() {
    const { suggestions, noAddressError } = this.state
    const { addressLoading, residentialOnlyError, formLocation } = this.props

    let { theme } = this.props
    if (!theme) {
      theme = defaultTheme
    }

    const hasError = residentialOnlyError || noAddressError

    let inputProps = {
      placeholder: 'Enter your delivery address',
      onChange: this.onChange,
      id: `e2e-home-address${formLocation === '' ? formLocation : '-' + formLocation}`,
      type: 'text',
      value: this.state.value,
      'x-autocompletetype': 'address',
      name: `e2e-home-address${formLocation === '' ? formLocation : '-' + formLocation}`
    }

    if (hasError) {
      inputProps = {
        ...inputProps,
        'aria-invalid': true,
        'aria-describedby': `address-error-message${formLocation === '' ? formLocation : '-' + formLocation}`
      }
    }

    return (
      <ClassNames>
        {({ css }) => (
          <AutoSuggestWrapper hasError={hasError}>
            <InputWrapper>
              <A11yHiddenLabel htmlFor={`e2e-home-address-${formLocation}`}>
                Enter your delivery address
              </A11yHiddenLabel>
              <Autosuggest
                suggestions={suggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={this.handleSelect}
                onSuggestionsFetchRequested={debounce(this.retrieveSuggestions, 200)}
                focusFirstSuggestion={!isMobile}
                focusInputOnSuggestionClick={false}
                onSuggestionsClearRequested={() => {}} /* not sure why this is required */
                inputProps={{ ...inputProps, className: css(hasError ? inputErr : inputClassCss) }}
                ref={this.saveInput}
                theme={theme}
              />
              <AutoSuggestError
                id={`address-error-message${formLocation === '' ? formLocation : '-' + formLocation}`}
                hasError={hasError}>
                {hasError &&
                  (noAddressError ? addressErrorMessages.noInput : addressErrorMessages.residentialOnlyError)}
              </AutoSuggestError>
              <A11yHiddenLabel as="div" aria-live="assertive">
                {hasError &&
                  (noAddressError ? addressErrorMessages.noInput : addressErrorMessages.residentialOnlyError)}
              </A11yHiddenLabel>
            </InputWrapper>
            <Button onClick={this.handleClick} loading={addressLoading} hasError={hasError}>
              {`Shop Now`}
            </Button>
          </AutoSuggestWrapper>
        )}
      </ClassNames>
    )
  }
}

const AutoSuggestError = styled.div`
  width: 100%;
  position: absolute;
  display: ${({ hasError }) => (hasError ? 'block' : 'none')};
  padding: ${({ theme }) => theme.spacing.xs}px;
  background-color: ${Colors.danger};
  text-align: center;
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.typography.size.desktop.caption}px;
`

const AutoSuggestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: calc(${({ theme }) => theme.spacing.default}px * 2);
`

const InputWrapper = styled.div`
  position: relative;
  width: 330px;

  @media (min-width: ${breakpoint.min.md}px) {
    width: 375px;
  }
`

const inputClassCss = css`
  width: 330px;
  padding: 0 14px 0 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 70px;
  -webkit-appearance: none;

  @media (min-width: ${breakpoint.min.md}px) {
    width: 375px;
  }

  &::placeholder {
    font-family: ${lightTheme.typography.font.sim};
    color: ${lightTheme.colors.textDisabled};
  }

  &:focus {
    outline: none;
  }
`

const inputErr = css`
  ${inputClassCss};

  border: 1px solid ${Colors.danger};

  @media (min-width: ${breakpoint.min.md}px) {
    border: 2px solid ${Colors.danger};
  }
`

const hasMaps = withMaps(AutoComplete)
const hasRouter = withRouter(hasMaps)

export default hasRouter
